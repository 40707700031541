import 'regenerator-runtime/runtime';
// import polyfills
interface Element {
	msMatchesSelector(selectors: string): boolean;
}

if (!Element.prototype.matches) {
	Element.prototype.matches = (Element.prototype as any).msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

import 'core-js/stable';
import 'core-js/proposals/reflect-metadata';
import 'zone.js/dist/zone';
